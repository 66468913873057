import { useState } from 'react';

import type { DocumentNode, FetchResult } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type {
  Action,
  DefaultAction,
  CreateAppointmentForHaloPsaMutation,
  CreateAppointmentForHaloPsaMutationVariables,
  CreateCreateActionOnTicketForHaloPsaMutation,
  CreateCreateActionOnTicketForHaloPsaMutationVariables,
  CreateCreateActivityForConnectWiseMutation,
  CreateCreateActivityForConnectWiseMutationVariables,
  CreateCreateCompanyTodoForAutotaskMutation,
  CreateCreateCompanyTodoForAutotaskMutationVariables,
  CreateCreateContactForAutotaskMutation,
  CreateCreateContactForAutotaskMutationVariables,
  CreateCreateContactForConnectWiseMutation,
  CreateCreateContactForConnectWiseMutationVariables,
  CreateCreateContactForHubSpotMutation,
  CreateCreateContactForHubSpotMutationVariables,
  CreateCreateEventForOffice365Mutation,
  CreateCreateEventForOffice365MutationVariables,
  CreateCreateMeetingForHubSpotMutation,
  CreateCreateMeetingForHubSpotMutationVariables,
  CreateCreateNoteOnTicketForAutotaskMutation,
  CreateCreateNoteOnTicketForAutotaskMutationVariables,
  CreateCreateNoteOnTicketForConnectWiseMutation,
  CreateCreateNoteOnTicketForConnectWiseMutationVariables,
  CreateCreateScheduleEntryForConnectWiseMutation,
  CreateCreateScheduleEntryForConnectWiseMutationVariables,
  CreateCreateServiceCallForAutotaskMutation,
  CreateCreateServiceCallForAutotaskMutationVariables,
  CreateCreateServiceTicketForAutotaskMutation,
  CreateCreateServiceTicketForAutotaskMutationVariables,
  CreateCreateServiceTicketForConnectWiseMutation,
  CreateCreateServiceTicketForConnectWiseMutationVariables,
  CreateCreateUserForHaloPsaMutation,
  CreateCreateUserForHaloPsaMutationVariables,
  CreateMoveTicketToBoardAndStatusForConnectWiseMutation,
  CreateMoveTicketToBoardAndStatusForConnectWiseMutationVariables,
  CreateOpportunityForAutotaskMutation,
  CreateOpportunityForAutotaskMutationVariables,
  CreateOpportunityForConnectWiseMutation,
  CreateOpportunityForConnectWiseMutationVariables,
  CreateSendEmailForTimeZestMutation,
  CreateSendEmailForTimeZestMutationVariables,
  CreateSendEmailFromTemplateForTimeZestMutation,
  CreateSendEmailFromTemplateForTimeZestMutationVariables,
  CreateSendMessageToScheduledUsersMutation,
  CreateSendMessageToScheduledUsersMutationVariables,
  CreateSendMessageToTeamChannelMutation,
  CreateSendMessageToTeamChannelMutationVariables,
  CreateTicketForHaloPsaMutation,
  CreateTicketForHaloPsaMutationVariables,
  CreateUpdateTicketStatusForAutotaskMutation,
  CreateUpdateTicketStatusForAutotaskMutationVariables,
  CreateUpdateTicketStatusForConnectWiseMutation,
  CreateUpdateTicketStatusForConnectWiseMutationVariables,
  CreateUpdateTicketStatusForHaloPsaMutation,
  CreateUpdateTicketStatusForHaloPsaMutationVariables,
  CreateUpdateTicketToPresetStatusForHaloPsaMutation,
  CreateUpdateTicketToPresetStatusForAutotaskMutation,
  CreateUpdateTicketToPresetStatusForHaloPsaMutationVariables,
  CreateUpdateTicketToPresetStatusForAutotaskMutationVariables,
  UpdateAppointmentForHaloPsaMutation,
  UpdateAppointmentForHaloPsaMutationVariables,
  UpdateCreateActionOnTicketForHaloPsaMutation,
  UpdateCreateActivityForConnectWiseMutation,
  UpdateCreateActivityForConnectWiseMutationVariables,
  UpdateCreateCompanyTodoForAutotaskMutation,
  UpdateCreateCompanyTodoForAutotaskMutationVariables,
  UpdateCreateContactForAutotaskMutation,
  UpdateCreateContactForAutotaskMutationVariables,
  UpdateCreateContactForConnectWiseMutation,
  UpdateCreateContactForConnectWiseMutationVariables,
  UpdateCreateContactForHubSpotMutation,
  UpdateCreateContactForHubSpotMutationVariables,
  UpdateCreateEventForOffice365Mutation,
  UpdateCreateEventForOffice365MutationVariables,
  UpdateCreateMeetingForHubSpotMutation,
  UpdateCreateMeetingForHubSpotMutationVariables,
  UpdateCreateNoteOnTicketForAutotaskMutation,
  UpdateCreateNoteOnTicketForAutotaskMutationVariables,
  UpdateCreateNoteOnTicketForConnectWiseMutation,
  UpdateCreateNoteOnTicketForConnectWiseMutationVariables,
  UpdateCreateScheduleEntryForConnectWiseMutation,
  UpdateCreateScheduleEntryForConnectWiseMutationVariables,
  UpdateCreateServiceCallForAutotaskMutation,
  UpdateCreateServiceCallForAutotaskMutationVariables,
  UpdateCreateServiceTicketForAutotaskMutation,
  UpdateCreateServiceTicketForAutotaskMutationVariables,
  UpdateCreateServiceTicketForConnectWiseMutation,
  UpdateCreateServiceTicketForConnectWiseMutationVariables,
  UpdateCreateUserForHaloPsaMutation,
  UpdateCreateUserForHaloPsaMutationVariables,
  UpdateMoveTicketToBoardAndStatusForConnectWiseMutation,
  UpdateMoveTicketToBoardAndStatusForConnectWiseMutationVariables,
  UpdateOpportunityForAutotaskMutation,
  UpdateOpportunityForAutotaskMutationVariables,
  UpdateOpportunityForConnectWiseMutation,
  UpdateOpportunityForConnectWiseMutationVariables,
  UpdateSendEmailForTimeZestMutation,
  UpdateSendEmailForTimeZestMutationVariables,
  UpdateSendEmailFromTemplateForTimeZestMutation,
  UpdateSendEmailFromTemplateForTimeZestMutationVariables,
  UpdateSendMessageToScheduledUsersMutation,
  UpdateSendMessageToScheduledUsersMutationVariables,
  UpdateSendMessageToTeamChannelMutation,
  UpdateSendMessageToTeamChannelMutationVariables,
  UpdateTicketForHaloPsaMutation,
  UpdateTicketForHaloPsaMutationVariables,
  UpdateUpdateTicketStatusForAutotaskMutation,
  UpdateUpdateTicketStatusForAutotaskMutationVariables,
  UpdateUpdateTicketStatusForConnectWiseMutation,
  UpdateUpdateTicketStatusForConnectWiseMutationVariables,
  UpdateUpdateTicketStatusForHaloPsaMutation,
  UpdateUpdateTicketStatusForHaloPsaMutationVariables,
  UpdateUpdateTicketToPresetStatusForHaloPsaMutation,
  UpdateUpdateTicketToPresetStatusForHaloPsaMutationVariables,
  UpdateUpdateTicketToPresetStatusForAutotaskMutation,
  UpdateUpdateTicketToPresetStatusForAutotaskMutationVariables,
  WriteNoteToTaskForServiceNowMutation,
  WriteNoteToTaskForServiceNowMutationVariables,
  UpdateWriteNoteToTaskForServiceNowMutation,
  UpdateWriteNoteToTaskForServiceNowMutationVariables,
  UpdateTaskStateForServiceNowMutation,
  UpdateTaskStateForServiceNowMutationVariables,
  UpdateUpdateTaskStateForServiceNowMutation,
  UpdateUpdateTaskStateForServiceNowMutationVariables,
  UpdateTaskToPresetStateForServiceNowMutation,
  UpdateUpdateTaskToPresetStateForServiceNowMutation,
  UpdateTaskToPresetStateForServiceNowMutationVariables,
  UpdateUpdateTaskToPresetStateForServiceNowMutationVariables,
  UpdateCreateActionOnTicketForHaloPsaMutationVariables,
} from '@graphql/generated';
import {
  CreateAppointmentForHaloPsaDocument,
  CreateCreateActionOnTicketForHaloPsaDocument,
  CreateCreateActivityForConnectWiseDocument,
  CreateCreateCompanyTodoForAutotaskDocument,
  CreateCreateContactForAutotaskDocument,
  CreateCreateContactForConnectWiseDocument,
  CreateCreateContactForHubSpotDocument,
  CreateCreateEventForOffice365Document,
  CreateCreateMeetingForHubSpotDocument,
  CreateCreateNoteOnTicketForAutotaskDocument,
  CreateCreateNoteOnTicketForConnectWiseDocument,
  CreateCreateScheduleEntryForConnectWiseDocument,
  CreateCreateServiceCallForAutotaskDocument,
  CreateCreateServiceTicketForAutotaskDocument,
  CreateCreateServiceTicketForConnectWiseDocument,
  CreateCreateUserForHaloPsaDocument,
  CreateMoveTicketToBoardAndStatusForConnectWiseDocument,
  CreateOpportunityForAutotaskDocument,
  CreateOpportunityForConnectWiseDocument,
  CreateSendEmailForTimeZestDocument,
  CreateSendEmailFromTemplateForTimeZestDocument,
  CreateSendMessageToScheduledUsersDocument,
  CreateSendMessageToTeamChannelDocument,
  CreateTicketForHaloPsaDocument,
  CreateUpdateTicketStatusForAutotaskDocument,
  CreateUpdateTicketStatusForConnectWiseDocument,
  CreateUpdateTicketStatusForHaloPsaDocument,
  CreateUpdateTicketToPresetStatusForHaloPsaDocument,
  CreateUpdateTicketToPresetStatusForAutotaskDocument,
  UpdateAppointmentForHaloPsaDocument,
  UpdateCreateActionOnTicketForHaloPsaDocument,
  UpdateCreateActivityForConnectWiseDocument,
  UpdateCreateCompanyTodoForAutotaskDocument,
  UpdateCreateContactForAutotaskDocument,
  UpdateCreateContactForConnectWiseDocument,
  UpdateCreateContactForHubSpotDocument,
  UpdateCreateEventForOffice365Document,
  UpdateCreateMeetingForHubSpotDocument,
  UpdateCreateNoteOnTicketForAutotaskDocument,
  UpdateCreateNoteOnTicketForConnectWiseDocument,
  UpdateCreateScheduleEntryForConnectWiseDocument,
  UpdateCreateServiceCallForAutotaskDocument,
  UpdateCreateServiceTicketForAutotaskDocument,
  UpdateCreateServiceTicketForConnectWiseDocument,
  UpdateCreateUserForHaloPsaDocument,
  UpdateMoveTicketToBoardAndStatusForConnectWiseDocument,
  UpdateOpportunityForAutotaskDocument,
  UpdateOpportunityForConnectWiseDocument,
  UpdateSendEmailForTimeZestDocument,
  UpdateSendEmailFromTemplateForTimeZestDocument,
  UpdateSendMessageToScheduledUsersDocument,
  UpdateSendMessageToTeamChannelDocument,
  UpdateTicketForHaloPsaDocument,
  UpdateUpdateTicketStatusForAutotaskDocument,
  UpdateUpdateTicketStatusForConnectWiseDocument,
  UpdateUpdateTicketStatusForHaloPsaDocument,
  UpdateUpdateTicketToPresetStatusForHaloPsaDocument,
  UpdateUpdateTicketToPresetStatusForAutotaskDocument,
  WriteNoteToTaskForServiceNowDocument,
  UpdateWriteNoteToTaskForServiceNowDocument,
  UpdateTaskStateForServiceNowDocument,
  UpdateUpdateTaskStateForServiceNowDocument,
  UpdateTaskToPresetStateForServiceNowDocument,
  UpdateUpdateTaskToPresetStateForServiceNowDocument,
} from '@graphql/generated';
import { refetchOnSuccess } from '@shared/apollo';

type CreateMutation =
  | CreateAppointmentForHaloPsaMutation
  | CreateCreateActionOnTicketForHaloPsaMutation
  | CreateCreateActivityForConnectWiseMutation
  | CreateCreateCompanyTodoForAutotaskMutation
  | CreateCreateContactForAutotaskMutation
  | CreateCreateContactForConnectWiseMutation
  | CreateCreateContactForHubSpotMutation
  | CreateCreateEventForOffice365Mutation
  | CreateCreateMeetingForHubSpotMutation
  | CreateCreateNoteOnTicketForAutotaskMutation
  | CreateCreateNoteOnTicketForConnectWiseMutation
  | CreateCreateScheduleEntryForConnectWiseMutation
  | CreateCreateServiceCallForAutotaskMutation
  | CreateCreateServiceTicketForAutotaskMutation
  | CreateCreateServiceTicketForConnectWiseMutation
  | CreateCreateUserForHaloPsaMutation
  | CreateMoveTicketToBoardAndStatusForConnectWiseMutation
  | CreateOpportunityForAutotaskMutation
  | CreateOpportunityForConnectWiseMutation
  | CreateSendEmailForTimeZestMutation
  | CreateSendEmailForTimeZestMutation
  | CreateSendEmailFromTemplateForTimeZestMutation
  | CreateSendMessageToScheduledUsersMutation
  | CreateSendMessageToTeamChannelMutation
  | CreateTicketForHaloPsaMutation
  | CreateUpdateTicketStatusForAutotaskMutation
  | CreateUpdateTicketStatusForConnectWiseMutation
  | CreateUpdateTicketStatusForHaloPsaMutation
  | CreateUpdateTicketToPresetStatusForHaloPsaMutation
  | CreateUpdateTicketToPresetStatusForAutotaskMutation
  | UpdateTaskStateForServiceNowMutation
  | UpdateTaskToPresetStateForServiceNowMutation
  | WriteNoteToTaskForServiceNowMutation;

type UpdateMutation =
  | UpdateAppointmentForHaloPsaMutation
  | UpdateCreateActionOnTicketForHaloPsaMutation
  | UpdateCreateActivityForConnectWiseMutation
  | UpdateCreateCompanyTodoForAutotaskMutation
  | UpdateCreateContactForAutotaskMutation
  | UpdateCreateContactForConnectWiseMutation
  | UpdateCreateContactForHubSpotMutation
  | UpdateCreateEventForOffice365Mutation
  | UpdateCreateMeetingForHubSpotMutation
  | UpdateCreateNoteOnTicketForAutotaskMutation
  | UpdateCreateNoteOnTicketForConnectWiseMutation
  | UpdateCreateScheduleEntryForConnectWiseMutation
  | UpdateCreateServiceCallForAutotaskMutation
  | UpdateCreateServiceTicketForAutotaskMutation
  | UpdateCreateServiceTicketForConnectWiseMutation
  | UpdateCreateUserForHaloPsaMutation
  | UpdateMoveTicketToBoardAndStatusForConnectWiseMutation
  | UpdateOpportunityForAutotaskMutation
  | UpdateOpportunityForConnectWiseMutation
  | UpdateSendEmailForTimeZestMutation
  | UpdateSendEmailForTimeZestMutation
  | UpdateSendEmailFromTemplateForTimeZestMutation
  | UpdateSendMessageToScheduledUsersMutation
  | UpdateSendMessageToTeamChannelMutation
  | UpdateTicketForHaloPsaMutation
  | UpdateUpdateTicketStatusForAutotaskMutation
  | UpdateUpdateTicketStatusForConnectWiseMutation
  | UpdateUpdateTicketStatusForHaloPsaMutation
  | UpdateUpdateTicketToPresetStatusForHaloPsaMutation
  | UpdateUpdateTicketToPresetStatusForAutotaskMutation
  | UpdateUpdateTaskStateForServiceNowMutation
  | UpdateUpdateTaskToPresetStateForServiceNowMutation
  | UpdateWriteNoteToTaskForServiceNowMutation;

export const useCreateContactForAutotask = () =>
  useSaveAction<
    CreateCreateContactForAutotaskMutationVariables,
    UpdateCreateContactForAutotaskMutationVariables
  >(CreateCreateContactForAutotaskDocument, UpdateCreateContactForAutotaskDocument);

export const useCreateCompanyTodoForAutotask = () =>
  useSaveAction<
    CreateCreateCompanyTodoForAutotaskMutationVariables,
    UpdateCreateCompanyTodoForAutotaskMutationVariables
  >(CreateCreateCompanyTodoForAutotaskDocument, UpdateCreateCompanyTodoForAutotaskDocument);

export const useCreateNoteOnTicketForAutotask = () =>
  useSaveAction<
    CreateCreateNoteOnTicketForAutotaskMutationVariables,
    UpdateCreateNoteOnTicketForAutotaskMutationVariables
  >(CreateCreateNoteOnTicketForAutotaskDocument, UpdateCreateNoteOnTicketForAutotaskDocument);

export const useCreateServiceCallForAutotask = () =>
  useSaveAction<
    CreateCreateServiceCallForAutotaskMutationVariables,
    UpdateCreateServiceCallForAutotaskMutationVariables
  >(CreateCreateServiceCallForAutotaskDocument, UpdateCreateServiceCallForAutotaskDocument);

export const useCreateServiceTicketForAutotask = () =>
  useSaveAction<
    CreateCreateServiceTicketForAutotaskMutationVariables,
    UpdateCreateServiceTicketForAutotaskMutationVariables
  >(CreateCreateServiceTicketForAutotaskDocument, UpdateCreateServiceTicketForAutotaskDocument);

export const useUpdateTicketStatusForAutotask = () =>
  useSaveAction<
    CreateUpdateTicketStatusForAutotaskMutationVariables,
    UpdateUpdateTicketStatusForAutotaskMutationVariables
  >(CreateUpdateTicketStatusForAutotaskDocument, UpdateUpdateTicketStatusForAutotaskDocument);

export const useUpdateTicketToPresetStatusForAutotask = () =>
  useSaveAction<
    CreateUpdateTicketToPresetStatusForAutotaskMutationVariables,
    UpdateUpdateTicketToPresetStatusForAutotaskMutationVariables
  >(CreateUpdateTicketToPresetStatusForAutotaskDocument, UpdateUpdateTicketToPresetStatusForAutotaskDocument);

export const useCreateOpportunityForAutotask = () =>
  useSaveAction<
    CreateOpportunityForAutotaskMutationVariables,
    UpdateOpportunityForAutotaskMutationVariables
  >(CreateOpportunityForAutotaskDocument, UpdateOpportunityForAutotaskDocument);

export const useCreateActivityForConnectWise = () =>
  useSaveAction<
    CreateCreateActivityForConnectWiseMutationVariables,
    UpdateCreateActivityForConnectWiseMutationVariables
  >(CreateCreateActivityForConnectWiseDocument, UpdateCreateActivityForConnectWiseDocument);

export const useCreateContactForConnectWise = () =>
  useSaveAction<
    CreateCreateContactForConnectWiseMutationVariables,
    UpdateCreateContactForConnectWiseMutationVariables
  >(CreateCreateContactForConnectWiseDocument, UpdateCreateContactForConnectWiseDocument);

export const useCreateNoteOnTicketForConnectWise = () =>
  useSaveAction<
    CreateCreateNoteOnTicketForConnectWiseMutationVariables,
    UpdateCreateNoteOnTicketForConnectWiseMutationVariables
  >(CreateCreateNoteOnTicketForConnectWiseDocument, UpdateCreateNoteOnTicketForConnectWiseDocument);

export const useCreateScheduleEntryForConnectWise = () =>
  useSaveAction<
    CreateCreateScheduleEntryForConnectWiseMutationVariables,
    UpdateCreateScheduleEntryForConnectWiseMutationVariables
  >(CreateCreateScheduleEntryForConnectWiseDocument, UpdateCreateScheduleEntryForConnectWiseDocument);

export const useCreateServiceTicketForConnectWise = () =>
  useSaveAction<
    CreateCreateServiceTicketForConnectWiseMutationVariables,
    UpdateCreateServiceTicketForConnectWiseMutationVariables
  >(CreateCreateServiceTicketForConnectWiseDocument, UpdateCreateServiceTicketForConnectWiseDocument);

export const useMoveTicketToBoardAndStatusForConnectWise = () =>
  useSaveAction<
    CreateMoveTicketToBoardAndStatusForConnectWiseMutationVariables,
    UpdateMoveTicketToBoardAndStatusForConnectWiseMutationVariables
  >(CreateMoveTicketToBoardAndStatusForConnectWiseDocument, UpdateMoveTicketToBoardAndStatusForConnectWiseDocument);

export const useUpdateTicketStatusForConnectWise = () =>
  useSaveAction<
    CreateUpdateTicketStatusForConnectWiseMutationVariables,
    UpdateUpdateTicketStatusForConnectWiseMutationVariables
  >(CreateUpdateTicketStatusForConnectWiseDocument, UpdateUpdateTicketStatusForConnectWiseDocument);

export const useFindOrCreateOpportunityForConnectWise = () =>
  useSaveAction<
    CreateOpportunityForConnectWiseMutationVariables,
    UpdateOpportunityForConnectWiseMutationVariables
  >(CreateOpportunityForConnectWiseDocument, UpdateOpportunityForConnectWiseDocument);

export const useCreateEventForOffice365 = () =>
  useSaveAction<
    CreateCreateEventForOffice365MutationVariables,
    UpdateCreateEventForOffice365MutationVariables
  >(CreateCreateEventForOffice365Document, UpdateCreateEventForOffice365Document);

export const useCreateMeetingForHubSpot = () =>
  useSaveAction<
    CreateCreateMeetingForHubSpotMutationVariables,
    UpdateCreateMeetingForHubSpotMutationVariables
  >(CreateCreateMeetingForHubSpotDocument, UpdateCreateMeetingForHubSpotDocument);

export const useSendEmailFromTemplateForTimeZest = () =>
  useSaveAction<
    CreateSendEmailFromTemplateForTimeZestMutationVariables,
    UpdateSendEmailFromTemplateForTimeZestMutationVariables
  >(CreateSendEmailFromTemplateForTimeZestDocument, UpdateSendEmailFromTemplateForTimeZestDocument);

export const useSendEmailForTimeZest = () =>
  useSaveAction<
    CreateSendEmailForTimeZestMutationVariables,
    UpdateSendEmailForTimeZestMutationVariables
  >(CreateSendEmailForTimeZestDocument, UpdateSendEmailForTimeZestDocument);

export const useSendMessageToChannelForMSTeams = () =>
  useSaveAction<
    CreateSendMessageToTeamChannelMutationVariables,
    UpdateSendMessageToTeamChannelMutationVariables
  >(CreateSendMessageToTeamChannelDocument, UpdateSendMessageToTeamChannelDocument);

export const useSendMessageToScheduledUsersForMSTeams = () =>
  useSaveAction<
    CreateSendMessageToScheduledUsersMutationVariables,
    UpdateSendMessageToScheduledUsersMutationVariables
  >(CreateSendMessageToScheduledUsersDocument, UpdateSendMessageToScheduledUsersDocument);

// HaloPSA
export const useCreateTicketForHaloPsa = () =>
  useSaveAction<
    CreateTicketForHaloPsaMutationVariables,
    UpdateTicketForHaloPsaMutationVariables
  >(CreateTicketForHaloPsaDocument, UpdateTicketForHaloPsaDocument);

export const useCreateAppointmentForHaloPsa = () =>
  useSaveAction<
    CreateAppointmentForHaloPsaMutationVariables,
    UpdateAppointmentForHaloPsaMutationVariables
  >(CreateAppointmentForHaloPsaDocument, UpdateAppointmentForHaloPsaDocument);

export const useUpdateTicketStatusForHaloPsa = () =>
  useSaveAction<
    CreateUpdateTicketStatusForHaloPsaMutationVariables,
    UpdateUpdateTicketStatusForHaloPsaMutationVariables
  >(CreateUpdateTicketStatusForHaloPsaDocument, UpdateUpdateTicketStatusForHaloPsaDocument);

export const useUpdateTicketToPresetStatusForHaloPsa = () =>
  useSaveAction<
    CreateUpdateTicketToPresetStatusForHaloPsaMutationVariables,
    UpdateUpdateTicketToPresetStatusForHaloPsaMutationVariables
  >(CreateUpdateTicketToPresetStatusForHaloPsaDocument, UpdateUpdateTicketToPresetStatusForHaloPsaDocument);

export const useCreateActionOnTicketForHaloPsa = () =>
  useSaveAction<
    CreateCreateActionOnTicketForHaloPsaMutationVariables,
    UpdateCreateActionOnTicketForHaloPsaMutationVariables
  >(CreateCreateActionOnTicketForHaloPsaDocument, UpdateCreateActionOnTicketForHaloPsaDocument);

export const useCreateUserForHaloPsa = () =>
  useSaveAction<
    CreateCreateUserForHaloPsaMutationVariables,
    UpdateCreateUserForHaloPsaMutationVariables
  >(CreateCreateUserForHaloPsaDocument, UpdateCreateUserForHaloPsaDocument);

// HubSpot
export const useCreateContactForHubSpot = () =>
  useSaveAction<
    CreateCreateContactForHubSpotMutationVariables,
    UpdateCreateContactForHubSpotMutationVariables
  >(CreateCreateContactForHubSpotDocument, UpdateCreateContactForHubSpotDocument);

// ServiceNow
export const useWriteNoteToTaskForServiceNow = () =>
  useSaveAction<
    WriteNoteToTaskForServiceNowMutationVariables,
    UpdateWriteNoteToTaskForServiceNowMutationVariables
  >(WriteNoteToTaskForServiceNowDocument, UpdateWriteNoteToTaskForServiceNowDocument);

export const useUpdateTaskStateForServiceNow = () =>
  useSaveAction<
    UpdateTaskStateForServiceNowMutationVariables,
    UpdateUpdateTaskStateForServiceNowMutationVariables
  >(UpdateTaskStateForServiceNowDocument, UpdateUpdateTaskStateForServiceNowDocument);

export const useUpdateTaskToPresetStateForServiceNow = () =>
  useSaveAction<
    UpdateTaskToPresetStateForServiceNowMutationVariables,
    UpdateUpdateTaskToPresetStateForServiceNowMutationVariables
  >(UpdateTaskToPresetStateForServiceNowDocument, UpdateUpdateTaskToPresetStateForServiceNowDocument);

function useSaveAction<
  CV extends { triggerId: number },
  UV extends { actionId: number },
>(createMutationDocument: DocumentNode, updateMutationDocument: DocumentNode) {
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);

  const [createAction] = useMutation<CreateMutation, CV>(createMutationDocument, {
    refetchQueries: refetchOnSuccess('createAction', 'getWorkflowTriggers'),
  });
  const [updateAction] = useMutation<UpdateMutation, UV>(updateMutationDocument, {
    refetchQueries: refetchOnSuccess('updateAction', 'getWorkflowTriggers'),
  });

  return {
    errors,
    loading,
    succeeded,
    submit(action: Action, params: Omit<CV | UV, 'actionId' | 'triggerId'>) {
      setErrors({});
      setLoading(true);
      setSucceeded(false);

      const attributes = { ...params } as object;

      if (action.id > 0) {
        const variables = { actionId: action.id, ...attributes } as UV;

        updateAction({ variables })
          .then(
            res => {
              if (Object.keys(res.data?.updateAction.errors || {}).length > 0) {
                setErrors(res.data!.updateAction.errors);
              } else {
                setErrors({});
                setSucceeded(true);
              }
            },
            error => {
              setErrors({ server: error });
            }
          )
          .finally(() => setLoading(false));
      } else {
        const variables = { triggerId: action.triggerId, ...attributes } as CV;

        createAction({ variables })
          .then(
            res => {
              updateActionWithResult(action, res);

              if (Object.keys(res.data?.createAction.errors || {}).length > 0) {
                setErrors(res.data!.createAction.errors);
              } else {
                setErrors({});
                setSucceeded(true);
              }
            },
            error => {
              setErrors({ server: error });
            }
          )
          .finally(() => setLoading(false));
      }
    },
  };
}

function updateActionWithResult(action: Action, result: FetchResult<CreateMutation>) {
  if (!result?.data?.createAction.action) return;

  action.id = (result.data.createAction.action as DefaultAction).id;
}
