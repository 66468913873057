import React, { Component } from 'react';

import AccountContext from './AccountContext';
import CurrentUserContext from './CurrentUserContext';
import FeatureFlagContext, { type FeatureFlagContextType } from './FeatureFlagContext';
import FlashContext from './FlashContext';
import PlanContext from './PlanContext';
import ThemeProvider from './ThemeProvider';

import { GqlProvider } from '@shared/apollo';
import type Plan from '@models/Plan';
import type { Account } from '@models/Account';
import type User from '@models/User';
import { identifyUser } from '@shared/posthog';
import { setUserContext } from '@shared/sentry';

interface ScreenProps<T> {
  props: T;
  flash: [[string, string]] | [];
  plan: Plan;
  featureFlags: FeatureFlagContextType;
  account: Account;
  user: User;
}

interface ScreenState {
  user: User;
}

export default function asScreen<T>(Klass: React.ComponentType<any>, unmountEventName?: string) {
  return class extends Component<ScreenProps<T>, ScreenState> {
    constructor(props: ScreenProps<T>) {
      super(props);
      identifyUser(props.user, props.account, props.plan);
      setUserContext(props.user.id, props.account);
      this.state = {
        user: props.user,
      };
    }

    setUser = (data: Partial<User>) => {
      this.setState({ user: { ...this.state.user, ...data } });
    };

    componentDidMount() {
      window.addEventListener('beforeunload', () => window.posthog.capture(unmountEventName || '', {}));
    }

    componentWillUnmount() {
      window.removeEventListener('beforeunload', () => window.posthog.capture(unmountEventName || '', {}));
    }

    render() {
      return (
        <AccountContext.Provider value={this.props.account}>
          <PlanContext.Provider value={this.props.plan}>
            <FeatureFlagContext.Provider value={this.props.featureFlags}>
              <FlashContext.Provider value={this.props.flash}>
                <CurrentUserContext.Provider value={{ ...this.state.user, updateCurrentUser: this.setUser }}>
                  <ThemeProvider>
                    <GqlProvider>
                      <Klass {...this.props.props} />
                    </GqlProvider>
                  </ThemeProvider>
                </CurrentUserContext.Provider>
              </FlashContext.Provider>
            </FeatureFlagContext.Provider>
          </PlanContext.Provider>
        </AccountContext.Provider>
      );
    }
  };
}
