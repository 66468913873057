import type { FC } from 'react';
import { useContext, useState } from 'react';

import classNames from 'classnames';

import { DirtyContext } from '@/DirtyContext';

import useRefWithClickOutside from '@shared/hooks/useRefWithClickOutside';
import InputGroup from '@shared/ui/Inputs/InputGroup';

export type Unit = 'minutes' | 'hours' | 'days' | 'weeks';

interface Props {
  name: string;
  initialValue: number;
  unit: 'minutes' | 'days';
  units: Unit[];
  className?: string;
  disabled?: boolean;
  error?: string | null;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onChange?: (value: string) => void;
}

const Duration: FC<Props> = ({
  name,
  initialValue,
  unit: underlyingUnit,
  className,
  disabled,
  error,
  units,
  onKeyDown: handleKeyDown,
  onChange,
}) => {
  const context = useContext(DirtyContext);
  const [dropdownShowing, setDropdownShowing] = useState<boolean>(false);
  const [unit, setUnit] = useState<Unit>(detectUnit(initialValue, underlyingUnit, units));
  const [value, setValue] = useState(initialValue);
  const [valueStr, setValueStr] = useState<string>(convert(initialValue, underlyingUnit, unit).toString());

  const unitsDropdownMenu = useRefWithClickOutside<HTMLDivElement>(() => setDropdownShowing(false));

  const handleKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (!e.key.match(/^[0-9.]+$/)) e.preventDefault();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (context.handleDirty) context.handleDirty();

    if (Number(e.target.value) < 0) {
      setValue(0);
      setValueStr('');
      return;
    }

    const newValue = convert(Number(e.target.value), unit, underlyingUnit);

    setValue(newValue);
    setValueStr(e.target.value);

    if (onChange) onChange(newValue.toString());
  };

  const handleDropdownClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setDropdownShowing(!dropdownShowing);
  };

  const handleUnitSelection = (newUnit: Unit, e: React.MouseEvent): void => {
    e.preventDefault();
    setUnit(newUnit);
    setValue(convert(Number(valueStr), newUnit, underlyingUnit));
    setDropdownShowing(false);

    if (onChange) onChange(convert(Number(valueStr), newUnit, underlyingUnit).toString());
  };

  return (
    <>
      <InputGroup
        type="number"
        className={className}
        name=""
        error={error || undefined}
        disabled={disabled}
        ariaLabel="Text input with dropdown button"
        value={valueStr}
        append={
          <div ref={unitsDropdownMenu} className="position-relative" style={{ width: '85px' }}>
            <button
              className={classNames('btn', 'dropdown-toggle w-100 border', {
                'rounded-bottom-0': dropdownShowing,
                'btn-outline-danger': error,
                'btn-outline-form': !error,
                'btn-secondary': disabled,
              })}
              type="button"
              aria-haspopup="true"
              aria-expanded={!dropdownShowing}
              disabled={disabled}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={handleDropdownClick}
            >
              {unit}
            </button>
            <div
              className="dropdown-menu rounded-0 border-top-0 w-100 rounded-bottom-2 border p-0"
              style={{ display: dropdownShowing ? 'block' : 'none', right: 0, left: 'auto', minWidth: 'auto' }}
            >
              {units.map((unit, i) => (
                <button
                  key={unit}
                  className={classNames('dropdown-item', { 'rounded-bottom-2': i === units.length - 1 })}
                  onClick={handleUnitSelection.bind(null, unit)}
                >
                  {unit}
                </button>
              ))}
            </div>
          </div>
        }
        onChange={handleChange}
        onKeyPress={handleKeyPressed}
        onKeyDown={handleKeyDown}
      />

      <input type="hidden" name={name} value={value} />
    </>
  );
};

const UNITS = {
  minutes: { minutes: 1, hours: 1 / 60, days: 1 / 24 / 60, weeks: 1 / 7 / 24 / 60 },
  hours: { minutes: 60, hours: 1, days: 1 / 24, weeks: 1 / 24 / 7 },
  days: { minutes: 60 * 24, hours: 24, days: 1, weeks: 1 / 7 },
  weeks: { minutes: 7 * 24 * 60, hours: 7 * 24, days: 7, weeks: 1 },
};

function convert(value: number, from: Unit, to: Unit): number {
  const rate = UNITS[from][to];
  return Number((value * rate).toFixed(3));
}

function detectUnit(value: number, unit: Unit, units: Unit[]): Unit {
  const mins = convert(value, unit, 'minutes');
  let detected: Unit = 'minutes';

  if (mins >= 10080) {
    detected = 'weeks';
  } else if (mins > 1440) {
    detected = 'days';
  } else if (mins > 60) {
    detected = 'hours';
  }

  return units.includes(detected) ? detected : closestUnit(unit, units);
}

function closestUnit(unit: Unit, units: Unit[]): Unit {
  const i = Object.keys(UNITS).indexOf(unit);
  const start = Object.keys(UNITS).indexOf(units[0]);

  return i < start ? units[0] : units[units.length - 1];
}

export default Duration;
