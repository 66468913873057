import type { FC } from 'react';

import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';

import Settings from './Settings';
import Boards from './Boards';
import Configurations from './Configuration';

import Alert from '@shared/Alert';

export type IntegrationSettings = {
  companyId: string;
  connected: boolean;
  host: string;
  privateKey: string;
  publicKey: string;
};

export type QueryStatus = {
  badCredentials: boolean;
  notConfigured: boolean;
  notSupported: boolean;
  ok: boolean;
  unauthorized: boolean;
};

export type StaticIPConfiguration = {
  useStaticIp: boolean;
  ip1: string;
  ip2: string;
};

interface Props {
  applicationHost: string;
  url: string;
  staticIpConfiguration: StaticIPConfiguration;
  integration: IntegrationSettings;
  query: QueryStatus | null;
  errors: Record<string, string>;
}

const ConnectWise: FC<Props> = ({ applicationHost, url, staticIpConfiguration, integration, errors, query }) => {
  return (
    <div>
      <Header title="ConnectWise PSA Integration" />
      <Flash />
      {errors.base && (
        <Alert
          color="red"
          className="mb-3"
          icon="circle-xmark"
          title="TimeZest was unable to connect to your ConnectWise PSA instance with the credentials provided."
          content={<p className="mb-0">Reason from ConnectWise PSA: {errors.base}</p>}
        />
      )}
      <Settings integration={integration} errors={errors} />
      {integration.connected && query && (
        <>
          <hr />
          <Boards query={query} />
        </>
      )}
      <hr />
      <Configurations applicationHost={applicationHost} url={url} staticIpConfiguration={staticIpConfiguration} />
    </div>
  );
};

export default asScreen(ConnectWise);
