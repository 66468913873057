import type { FC } from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Row from '@/ui/Row';
import Switch from '@/ui/Switch';

import type { UserCalendarModel } from '@models/Calendar';

interface Props {
  calendar: UserCalendarModel;
  enabledToRead: boolean;
}

const UserCalendar: FC<Props> = ({ calendar, enabledToRead }) => {
  const { id, name, enabledToDisplay, enabledToModify, privateCalendar, privateCalendarToggleable } = calendar;

  return (
    <Row label={name}>
      <Switch
        value={enabledToRead}
        attr={`calendars[${id}][enabled_to_read]`}
        namespace="calendars-settins"
        label="Appointments on this calendar block customers from scheduling conflicting appointments"
        disabled={false}
      />
      <>
        <Switch
          value={enabledToDisplay}
          attr={`calendars[${id}][enabled_to_display]`}
          namespace="calendars-settings"
          label="Appointments on this calendar will be displayed in Calendar HQ"
          disabled={false}
        />
        <Switch
          value={enabledToModify}
          attr={`calendars[${id}][enabled_to_modify]`}
          namespace="calendars-settings"
          label="Appointments on this calendar will be modifiable in Calendar HQ"
          disabled={false}
        />
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip>Contact us if you would like to enable this for your account</Tooltip>}
          trigger={privateCalendarToggleable ? [] : ['hover', 'focus']}
        >
          <div>
            <Switch
              value={privateCalendar}
              attr={`calendars[${id}][private_calendar]`}
              namespace="calendars-settings"
              label='All appointments on this calendar will appear as "Private Appointment" in Calendar HQ'
              disabled={!privateCalendarToggleable}
            />
          </div>
        </OverlayTrigger>
      </>
    </Row>
  );
};

export default UserCalendar;
