import { createContext } from 'react';

import type User from '@models/User';

export enum UserRole {
  Administrator = 'administrator',
  User = 'user',
}

type UserContext = User & { updateCurrentUser: (data: Partial<User>) => void };

export const NULL_USER: UserContext = {
  id: 0,
  externalId: '',
  name: '',
  email: '',
  role: '',
  slug: '',
  photo: null,
  jobTitle: null,
  schedulable: false,
  resourcePageEnabled: false,
  twoFactorEnabled: false,
  analytics: {
    id: 'timezest-user-0',
    status: 'inactive',
  },
  updateCurrentUser: () => {},
};

export default createContext<UserContext>(NULL_USER);
