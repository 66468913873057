import { createContext } from 'react';

import type { Account } from '@models/Account';

export const NULL_ACCOUNT: Account = {
  id: 0,
  brandColor: '',
  countryCode: '',
  host: '',
  integrations: [{ id: 0, name: '', type: 'connect_wise', capabilities: [] }],
  lastPaymentSucceeded: false,
  name: '',
  psaType: 'connect_wise',
  psaName: '',
  status: 'trial',
  totalUsers: 0,
  twoFactorForced: false,
  useCaching: true,
};

export default createContext<Account>(NULL_ACCOUNT);
