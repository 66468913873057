import posthog from 'posthog-js';

import { Account } from '@models/Account';
import Plan from '@models/Plan';
import User from '@models/User';

declare global {
  interface Window {
    posthog: typeof posthog;
  }
}

type VALID_EVENT_NAMES =
  | 'dhq_opened'
  | 'dhq_closed'
  | 'dhq_flyout_opened'
  | 'dhq_flyout_closed'
  | 'dhq_admin_activated'
  | 'dhq_appointment_cancelled'
  | 'dhq_appointment_reassigned'
  | 'dhq_appointment_rescheduled';

if (process.env.POSTHOG_API_KEY) {
  posthog.init(process.env.POSTHOG_API_KEY, {
    api_host: process.env.POSTHOG_HOST,
    person_profiles: 'identified_only',
    autocapture: true,
    capture_pageview: false,
    capture_pageleave: false,
    capture_dead_clicks: false,
  });

  window.posthog = posthog;
}

export function identifyUser(user: User, account: Account, plan: Plan) {
  if (!process.env.POSTHOG_API_KEY) return;

  // the distinct id format will be account-name-user-id
  posthog.identify(
    user.analytics.id,
    {
      'Account Name': account.name,
      'Plan ID': plan.id,
      'Plan Name': plan.name,
      'User Role': user.role,
      'User Status': user.analytics.status,
    },
    {
      'Account ID': account.id,
      'PSA Name': account.psaName,
    }
  );
}

export function captureEvent(eventName: VALID_EVENT_NAMES, properties = {}) {
  if (!process.env.POSTHOG_API_KEY) return;

  posthog.capture(eventName, properties);
}
